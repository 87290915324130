export const templateItems = [



  {    group: "Jackbox Favorite",    label: "Fibbage      ",    weight: 5,    					order:  1     },
  {    group: "Jackbox Favorite",    label: "Quiplash     ",    weight: 5,    					order:  2     },
  {    group: "Jackbox Favorite",    label: "MurderTrivia ",    weight: 5,    					order:  3     },
  {    group: "Jackbox Favorite",    label: "Guesspionage ",    weight: 5,    					order:  4     },
  {    group: "Jackbox Favorite",    label: "Dictionarium ",    weight: 5,    					order:  5     },
  {    group: "Jackbox Favorite",    label: "Joke Boat    ",    weight: 5,    					order:  6     },
  {    group: "Jackbox Favorite",    label: "Blather Round",    weight: 5,    					order:  7     },
  {    group: "Jackbox Favorite",    label: "Job Job      ",    weight: 5,    					order:  8     },
  {    group: "Jackbox Favorite",    label: "Timejinx     ",    weight: 5,    					order:  9     },
  {    group: "Jackbox Favorite",    label: "Fixy Text    ",    weight: 5,    					order:  10    },
  {    group: "Jackbox Favorite",    label: "RANDOM       ",    weight: 5,    					order:  11    },
         

  {    group: "Small Wheel",    label: "",    weight: 20,    					order:  1    },
  {    group: "Small Wheel",    label: "Keychain",    weight: 6,     	order:  2    },
  {    group: "Small Wheel",    label: "",    weight: 20,    					order:  3    },
  {    group: "Small Wheel",    label: "Keychain",    weight: 6,     	order:  4    },
  {    group: "Small Wheel",    label: "",    weight: 20,    					order:  5    },
  {    group: "Small Wheel",    label: "Keychain",    weight: 6,     	order:  6    },
  {    group: "Small Wheel",    label: "",    weight: 20,    					order:  7    },
  {    group: "Small Wheel",    label: "Sticker",    weight: 6,     	order:  8    },
  {    group: "Small Wheel",    label: "",    weight: 20,    					order:  9    },
  {    group: "Small Wheel",    label: "Keychain",    weight: 6,     	order:  10   },
  {    group: "Small Wheel",    label: "",    weight: 20,    					order:  11   },
  {    group: "Small Wheel",    label: "Keychain",    weight: 6,     	order:  12   },
  {    group: "Small Wheel",    label: "",    weight: 20,    					order:  13   },
  {    group: "Small Wheel",    label: "Keychain",    weight: 6,     	order:  14   },
  {    group: "Small Wheel",    label: "",    weight: 20,    					order:  15   },
  {    group: "Small Wheel",    label: "T-Shirt",    weight: 6,     	order:  16   },


  {    group: "Prize Wheel",    label: "Sticker",    weight: 15,    order:  1   },
  {    group: "Prize Wheel",    label: "Keychain or $5",    weight: 6,    order:  2   },
  {    group: "Prize Wheel",    label: "T-Shirt or $10",    weight: 3,    order:  3   },
  {    group: "Prize Wheel",    label: "Button or $5",    weight: 6,    order:  4   },
  {    group: "Prize Wheel",    label: "Pin or $5",    weight: 6,    order:  5   },
  {    group: "Prize Wheel",    label: "Respin",    weight: 4,    order:  6   },
  {    group: "Prize Wheel",    label: "T-Shirt or $10",    weight: 3,    order:  7   },
  {    group: "Prize Wheel",    label: "Button or $S",    weight: 6,    order:  8   },
  {    group: "Prize Wheel",    label: "Pin or $5",    weight: 6,    order:  9   },
  {    group: "Prize Wheel",    label: "Respin",    weight: 4,    order:  10  },
  {    group: "Prize Wheel",    label: "Keychain or $5",    weight: 6,    order:  11  },
  {    group: "Prize Wheel",    label: "T-Shirt or $10",    weight: 3,    order:  12  },
  {    group: "Prize Wheel",    label: "Buton or S5",    weight: 6,    order:  13  },
  {    group: "Prize Wheel",    label: "Pin or S5",    weight: 6,    order:  14  },
  {    group: "Prize Wheel",    label: "Sticker",    weight: 15,    order:  15  },
  {    group: "Prize Wheel",    label: "Keychain or $5",    weight: 6,    order:  16  },
  {    group: "Prize Wheel",    label: "Hoodie or $20",    weight: 3,    order:  17  },
  {    group: "Prize Wheel",    label: "Button or $5",    weight: 6,    order:  18  },
  {    group: "Prize Wheel",    label: "Pin or S5",    weight: 6,    order:  19  },
  {    group: "Prize Wheel",    label: "Respin",    weight: 12,    order:  20  },
  {    group: "Prize Wheel",    label: "Keychain or $5",    weight: 6,    order:  21  },
  {    group: "Prize Wheel",    label: "T-Shirt or $10",    weight: 3,    order:  22  },
  {    group: "Prize Wheel",    label: "Button or $5",    weight: 6,    order:  23  },
  {    group: "Prize Wheel",    label: "Pin or $5",    weight: 6,    order:  24  },



  {    group: "Big Wheel / Small Wheel",    label: "Big Wheel  ",    weight: 3,    		order: 1   },
  {    group: "Big Wheel / Small Wheel",    label: "",    						weight: 13,    	order: 2   },
  {    group: "Big Wheel / Small Wheel",    label: "Small Wheel",    weight: 6,    		order: 3   },
  {    group: "Big Wheel / Small Wheel",    label: "",    						weight: 13,    	order: 4   },
  {    group: "Big Wheel / Small Wheel",    label: "Big Wheel  ",    weight: 3,    		order: 5   },
  {    group: "Big Wheel / Small Wheel",    label: "",    						weight: 13,    	order: 6   },
  {    group: "Big Wheel / Small Wheel",    label: "Small Wheel",    weight: 6,    		order: 7   },
  {    group: "Big Wheel / Small Wheel",    label: "",    						weight: 13,    	order: 8   },
  {    group: "Big Wheel / Small Wheel",    label: "Big Wheel  ",    weight: 3,    		order: 9   },
  {    group: "Big Wheel / Small Wheel",    label: "",    						weight: 13,    	order: 10  },
  {    group: "Big Wheel / Small Wheel",    label: "Small Wheel",    weight: 6,    		order: 11  },
  {    group: "Big Wheel / Small Wheel",    label: "",    						weight: 13,    	order: 12  },
  {    group: "Big Wheel / Small Wheel",    label: "Big Wheel  ",    weight: 3,    		order: 13  },
  {    group: "Big Wheel / Small Wheel",    label: "",    						weight: 13,    	order: 14  },
  {    group: "Big Wheel / Small Wheel",    label: "Small Wheel",    weight: 6,    		order: 15  },
  {    group: "Big Wheel / Small Wheel",    label: "",    						weight: 13,    	order: 16  },



  {    group: "Games Wheel 1",    label: "Jackbox Wheel",    weight: 10,    order: 1   },
  {    group: "Games Wheel 1",    label: "Stream Racer",    weight: 5,    order: 2   },
  {    group: "Games Wheel 1",    label: "Marbles",    weight: 5,    order: 3   },
  {    group: "Games Wheel 1",    label: "Jackbox Wheel",    weight: 10,    order: 4   },
  {    group: "Games Wheel 1",    label: "Wordsonstream",    weight: 5,    order: 5   },
  {    group: "Games Wheel 1",    label: "Pummel Party",    weight: 5,    order: 6   },
  {    group: "Games Wheel 1",    label: "Jackbox Wheel",    weight: 10,    order: 7   },
  {    group: "Games Wheel 1",    label: "Party Animals",    weight: 5,    order: 8   },
  {    group: "Games Wheel 1",    label: "Fall Guys",    weight: 5,    order: 9   },
  {    group: "Games Wheel 1",    label: "Jackbox  Wheel",    weight: 10,    order: 10  },
  {    group: "Games Wheel 1",    label: "Tetris 99",    weight: 5,    order: 11  },
  {    group: "Games Wheel 1",    label: "Geoguesser",    weight: 5,    order: 12  },
  {    group: "Games Wheel 1",    label: "Games With Neko",    weight: 10,    order: 13  },



  {    group: "Jackbox Party Pack(s) Wheel",    label: "Jackbox Party Pack 1",    weight: 5,    order: 1   },
  {    group: "Jackbox Party Pack(s) Wheel",    label: "Jackbox Party Pack 2",    weight: 5,    order: 2   },
  {    group: "Jackbox Party Pack(s) Wheel",    label: "Jackbox Party Pack 3",    weight: 5,    order: 3   },
  {    group: "Jackbox Party Pack(s) Wheel",    label: "Jackbox Party Pack 4",    weight: 5,    order: 4   },
  {    group: "Jackbox Party Pack(s) Wheel",    label: "Jackbox Party Pack 5",    weight: 5,    order: 5   },
  {    group: "Jackbox Party Pack(s) Wheel",    label: "Jackbox Party Pack 6",    weight: 5,    order: 6   },
  {    group: "Jackbox Party Pack(s) Wheel",    label: "Jackbox Party Pack 7",    weight: 5,    order: 7   },
  {    group: "Jackbox Party Pack(s) Wheel",    label: "Jackbox Party Pack 8",    weight: 5,    order: 8   },
  {    group: "Jackbox Party Pack(s) Wheel",    label: "Jackbox Party Pack 9",    weight: 5,    order: 9   },
  {    group: "Jackbox Party Pack(s) Wheel",    label: "Jackbox Party Pack 10",    weight: 5,    order: 10  },





  {    group: "Jackbox Party Pack 1",    label: "1. You Don't Know Jack� 2015",    weight: 5,    order: 1  },
  {    group: "Jackbox Party Pack 1",    label: "1. Drawful",    weight: 5,    order: 2  },
  {    group: "Jackbox Party Pack 1",    label: "1. Word Spud",    weight: 5,    order: 3  },
  {    group: "Jackbox Party Pack 1",    label: "1. Lie Swatter",    weight: 5,    order: 4  },
  {    group: "Jackbox Party Pack 1",    label: "1. Fibbage XL",    weight: 5,    order: 5  },

  {    group: "Jackbox Party Pack 2",    label: "2. Fibbage 2",    weight: 5,    order: 1  },
  {    group: "Jackbox Party Pack 2",    label: "2. Earwax�",    weight: 5,    order: 2  },
  {    group: "Jackbox Party Pack 2",    label: "2. Bidiots",    weight: 5,    order: 3  },
  {    group: "Jackbox Party Pack 2",    label: "2. Quiplash XL",    weight: 5,    order: 4  },
  {    group: "Jackbox Party Pack 2",    label: "2. Bomb Corp.",    weight: 5,    order: 5  },

  {    group: "Jackbox Party Pack 3",    label: "3. Quiplash 2",    weight: 5,    order: 1  },
  {    group: "Jackbox Party Pack 3",    label: "3. Trivia Murder Party",    weight: 5,    order: 2  },
  {    group: "Jackbox Party Pack 3",    label: "3. Guesspionage",    weight: 5,    order: 3  },
  {    group: "Jackbox Party Pack 3",    label: "3. Fakin' It!",    weight: 5,    order: 4  },
  {    group: "Jackbox Party Pack 3",    label: "3. Tee K.O. T-Shirt Knock Out",    weight: 5,    order: 5  },

  {    group: "Jackbox Party Pack 4",    label: "4. Fibbage 3",    weight: 5,    order: 1  },
  {    group: "Jackbox Party Pack 4",    label: "4. Survive the Internet",    weight: 5,    order: 2  },
  {    group: "Jackbox Party Pack 4",    label: "4. Monster Seeking Monster",    weight: 5,    order: 3  },
  {    group: "Jackbox Party Pack 4",    label: "4. Bracketeering",    weight: 5,    order: 4  },
  {    group: "Jackbox Party Pack 4",    label: "4. Civic Doodle",    weight: 5,    order: 5  },

  {    group: "Jackbox Party Pack 5",    label: "5. You Don't Know Jack� Full Stream",    weight: 5,    order: 1  },
  {    group: "Jackbox Party Pack 5",    label: "5. Split the Room",    weight: 5,    order: 2  },
  {    group: "Jackbox Party Pack 5",    label: "5. Mad Verse City",    weight: 5,    order: 3  },
  {    group: "Jackbox Party Pack 5",    label: "5. Zeeple Dome",    weight: 5,    order: 4  },
  {    group: "Jackbox Party Pack 5",    label: "5. Patently Stupid",    weight: 5,    order: 5  },

  {    group: "Jackbox Party Pack 6",    label: "6. Trivia Murder Party 2",    weight: 5,    order: 1  },
  {    group: "Jackbox Party Pack 6",    label: "6. Role Models",    weight: 5,    order: 2  },
  {    group: "Jackbox Party Pack 6",    label: "6. Joke Boat",    weight: 5,    order: 3  },
  {    group: "Jackbox Party Pack 6",    label: "6. Dictionarium",    weight: 5,    order: 4  },
  {    group: "Jackbox Party Pack 6",    label: "6. Push the Button",    weight: 5,    order: 5  },

  {    group: "Jackbox Party Pack 7",    label: "7. Quiplash 3",    weight: 5,    order: 1  },
  {    group: "Jackbox Party Pack 7",    label: "7. The Devils and the Details",    weight: 5,    order: 2  },
  {    group: "Jackbox Party Pack 7",    label: "7. Champ'd Up",    weight: 5,    order: 3  },
  {    group: "Jackbox Party Pack 7",    label: "7. Talking Points",    weight: 5,    order: 4  },
  {    group: "Jackbox Party Pack 7",    label: "7. Blather 'Round",    weight: 5,    order: 5  },

  {    group: "Jackbox Party Pack 8",    label: "8. Drawful Animate",    weight: 5,    order: 1  },
  {    group: "Jackbox Party Pack 8",    label: "8. The Wheel of Enormous Proportions",    weight: 5,    order: 2  },
  {    group: "Jackbox Party Pack 8",    label: "8. Job Job",    weight: 5,    order: 3  },
  {    group: "Jackbox Party Pack 8",    label: "8. The Poll Mine",    weight: 5,    order: 4  },
  {    group: "Jackbox Party Pack 8",    label: "8. Weapons Drawn",    weight: 5,    order: 5  },

  {    group: "Jackbox Party Pack 9",    label: "9. Fibbage 4",    weight: 5,    order: 1  },
  {    group: "Jackbox Party Pack 9",    label: "9. Roomerang",    weight: 5,    order: 2  },
  {    group: "Jackbox Party Pack 9",    label: "9. Junktopia",    weight: 5,    order: 3  },
  {    group: "Jackbox Party Pack 9",    label: "9. Nonsensory",    weight: 5,    order: 4  },
  {    group: "Jackbox Party Pack 9",    label: "9. Quixort",    weight: 5,    order: 5  },

  {    group: "Jackbox Party Pack 10",    label: "10. Tee K.O. 2",    weight: 5,    order: 1  },
  {    group: "Jackbox Party Pack 10",    label: "10. Time Jinx",    weight: 5,    order: 2  },
  {    group: "Jackbox Party Pack 10",    label: "10. Fixy Text",    weight: 5,    order: 3  },
  {    group: "Jackbox Party Pack 10",    label: "10. Dodo Re Mi",    weight: 5,    order: 4  },
  {    group: "Jackbox Party Pack 10",    label: "10. Hypnotorious",    weight: 5,    order: 5  },



  {    group: "TEST-M1r -  Who's the next winner? 9L!",    label: 'Jim',    weight: 5,    order: 0  },
  {
    group: "TEST-M1r -  Who's the next winner? 9L!",
    label: 'John',
    weight: 1,
    order: 1
  },
  {
    group: "TEST-M1r -  Who's the next winner? 9L!",
    label: 'Will',
    weight: 5,
    order: 2
  },

];
